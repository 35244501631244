import React from 'react'

import { Paragraph, Spinner } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Spinner"
    components={[{ component: Spinner }]}
  >
    <Section>
      <Playground>
        <Spinner />
      </Playground>
      <Playground>
        <Spinner>
          <Paragraph noMargin>
            Ilmoituksen lähettäminen kestää normaalia pidempään. Odota muutama
            minuutti.
          </Paragraph>
        </Spinner>
      </Playground>
      <Playground>
        <Spinner
          ariaLabel="Ladataan työpaikkailmoituksia…"
          size="sm"
        />
      </Playground>
      <Playground>
        <Spinner
          align="center"
          ariaLabel="Ladataan…"
        />
      </Playground>
      <Playground>
        <Spinner align="center">Päivitämme kohtaantotuloksia…</Spinner>
      </Playground>
    </Section>
  </Content>
)

export default Page
